<template>
    <div :id="id" :class="`card ${[noThumbnail ? `card--no-thumbnail` : ``]} ${classes}`">
        <span v-if="draft" class="card__badge">
            <Badge type="tags" text="Draft" variant="light" />
        </span>
        <div v-if="image" class="card-img-top" :style="`background: url('${image}') no-repeat center center; background-size: cover;`"></div>
        <div class="card-body">
            <h2 class="card-title">{{ title }}</h2>
            <p class="card-text">{{ text }}</p>
            <div v-if="to || toPreview || toEdit" class="card__buttons d-flex justify-content-center">
                <div v-if="noThumbnail">
                    <Button text="Select" variant="primary" icon="icon-arrow-right" iconPosition="right" :to="to" />
                </div>
                <div v-else>
                    <Button v-if="toPreview" text="Preview" variant="outline-secondary" :to="toPreview" />
                    <Button v-if="toEdit" text="Edit" variant="primary" iconPosition="left" icon="icon-edit" :to="toEdit" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Badge from '../components/Badge.vue';
import Button from '../components/Button.vue';

export default {
    name: 'Card',

    components: {
        Badge,
        Button,
    },

    props: {
        classes: {
            type: String,
            required: false,
        },
        draft: {
            type: Boolean,
            default: false,
            required: false,
        },
        id: {
            type: String,
            required: false,
        },
        image: {
            type: String,
            default: null,
            required: false,
        },
        noThumbnail: {
            type: Boolean,
            default: false,
            required: false,
        },
        title: {
            type: String,
            default: null,
            required: false,
        },
        text: {
            type: String,
            default: null,
            required: false,
        },
        to: {
            type: String,
            default: '',
            required: false,
        },
        toEdit: {
            type: String,
            default: '',
            required: false,
        },
        toPreview: {
            type: String,
            default: '',
            required: false,
        },
    }
}
</script>
