<template>
    <component :is="tag" v-if="editable || text" :id="id" :class="`badge rounded-pill bg-${variant} ${classes}`">
        <span class="badge__icon" v-if="icon">
            <inline-svg :src="require(`../assets/svg/${icon}.svg`)" />
        </span>
        <input v-if="editable" type="text" :placeholder="!placeholder ? 'Keyword' : placeholder" :value="modelValue" @input="dynamicInput($event); $emit('update:modelValue', $event.target.value);">
        <span v-else :class="`badge__text ${[ !icon ? 'badge__text--keyword' : '' ]}`">{{ text }}</span>
        <button v-if="editable" class="badge__close" @click="closeBadge($event); removeData(index, type);">
            <inline-svg :src="require(`../assets/svg/icon-close.svg`)" />
        </button>
    </component>
</template>

<script>
    import InlineSvg from 'vue-inline-svg';

    export default {
        name: 'Badge',

        components: {
            InlineSvg
        },

        props: {
            classes: {
                type: String,
                required: false,
            },
            modelValue: {
                type: String,
                required: false,
            },
            editable: {
                type: Boolean,
                required: false,
                default: false,
            },
            icon: {
                type: String,
                required: false,
            },
            id: {
                type: String,
                required: false,
            },
            index: {
                type: Number,
                required: false,
            },
            placeholder: {
                type: String,
                required: false,
            },
            tag: {
                type: String,
                required: false,
                default: 'span'
            },
            text: {
                type: String,
                required: false,
            },
            type: {
                type: String,
                required: false,
            },
            variant: {
                type: String,
                required: false,
                default: 'primary',
            },
        },

        emits: ['removeElement'],

        methods: {
            closeBadge: function(e) {
                e.target.parentElement.classList.add('hide');
            },
            removeData: function(index, type) {
                this.$parent.removeElement(index, type);
            },

            dynamicInput: function(e) {
                if(e.keyCode == 46) {
                    e.target.style.width = ((e.target.value.length - 2)) + 'ch';
                } else {
                    e.target.style.width = ((e.target.value.length + 2)) + 'ch';
                }
            },
        },
    }
</script>
