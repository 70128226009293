<template>
    <div class="p-choose-persona-type">
        <div class="container-xl">
            <PageHeader title="New persona" text="Choose the persona type that best fits your project:" goBack />
            <div class="row">
                <div class="col-md-6 col-">
                    <Card title="Role-based persona" text="What’s the user role and behavior in my organization?" :to="`/${$route.params.projectName}/edit/role-based/new`" noThumbnail />
                </div>
                <div class="col-md-6 col-">
                    <Card title="Goal-directed persona" text="What does my typical user want to do with my product?" :to="`/${$route.params.projectName}/edit/goal-directed/new`" noThumbnail />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Card from '../components/Card.vue';
    import PageHeader from '../components/PageHeader.vue';

    export default {
        name: 'ChoosePersonaType',

        components: {
            Card,
            PageHeader,
        },
    }
</script>
