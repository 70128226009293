<template>
    <div class="page-header">
        <div class="page-header__buttons-section d-flex align-items-center justify-content-between">
            <div>
                <span v-if="goBack">
                    <Button @click="back" text="Back" variant="outline-secondary" iconPosition="left" icon="icon-arrow-left" />
                </span>
            </div>
            <div>
                <slot />
            </div>
        </div>
        <h1>{{ title }} <button type="button" v-if="selectBtn" class="edit-btn" @click="openModal(selectId)"><inline-svg :src="require(`../assets/svg/icon-edit.svg`)" /></button></h1>
        <p>{{ text }}</p>
    </div>
</template>

<script>
    import * as bootstrap from 'bootstrap';
    import InlineSvg from 'vue-inline-svg';
    import Button from '../components/Button.vue';

    export default {
        name: 'PageHeader',

        components: {
            Button,
            InlineSvg,
        },

        emits: ['openModal'],

        props: {
            goBack: {
                type: Boolean,
                required: false,
                default: false,
            },
            modalId: {
                type: String,
                required: false,
                default: '',
            },
            selectBtn: {
                type: Boolean,
                required: false,
                default: false,
            },
            selectId: {
                type: String,
                required: false,
                default: 'selectProjectModal',
            },
            title: {
                type: String,
                required: false,
            },
            text: {
                type: String,
                required: false,
            },
            personaSaved: {
                type: Boolean,
                required: false,
            } 
        },

        data() {
            return {
                onProjectPage: false,
                onPersonaProfilePage: false,
            }
        },

        methods: {
            back() {
                if (this.onPersonaProfilePage && this.personaSaved) {
                    this.$router.push({ name: 'Project', params: { name: this.$route.params.projectName } })
                } else if (this.onPersonaProfilePage && !this.personaSaved) {
                    this.$emit('openModal', 'changesModal');
                } else if (this.onProjectPage) {
                    this.$router.push({ name: 'Home' })
                } else {
                    this.$router.go(-1);
                }
            },
            openModal(modalId) {
                const myModalEl = document.getElementById(modalId);
                const modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
                modal.toggle();
            }
        },

        watch: {
            "$route.params.personaId": {
                handler: function(value) {
                    if(value !== undefined && this.$route.params.mode === 'edit') { // && value !== 'new'
                        this.onPersonaProfilePage = true;
                    } else {
                        this.onPersonaProfilePage = false;
                    }
                },
                deep: true,
                immediate: true,
            },
            "$route.params.name": {
                handler: function(value) {
                    if(value !== undefined) {
                        this.onProjectPage = true;
                    } else {
                        this.onProjectPage = false;
                    }
                },
                deep: true,
                immediate: true,
            },
        },
    }
</script>
